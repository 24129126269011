@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.rainwalk {
  &-main {
    background: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -99;
  }

  &__button {
    padding: 10px;
  }

  &__city {
    &-select {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 12px;
      background: #00000008;
      border-radius: 10px;
      max-height: 170px;
      overflow: scroll;
      overflow-x: hidden;

      &-disabled {
        @extend .rainwalk__city-select;
        background: #ced4da;
        pointer-events: none;
      }

      &_location {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;

        &-selected {
          @extend .rainwalk__city-select_location;
          border-radius: 10px;
          color: #fff;
          background: #12b886;
        }
      }
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &-loader {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 25;
      width: 100%;
      height: 100%;
      margin: auto;
      background-image: url(../static_noise.gif);
    }
  }
}

iframe {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 56.25vw;
}

html {
  font: {
    family: "Poppins", sans-serif;
  }
}

#yt-player {
  width: 100%;
  height: 100%;
}

.rhap_progress-section {
  display: flex;
  justify-content: center !important;
}

.rhap_controls-section {
  display: flex;
  justify-content: center !important;
}

.rhap_volume-controls {
  display: flex;
  justify-content: center !important;
}

.rhap_header {
  display: flex;
  justify-content: center !important;
}

.rhap_volume-bar-area {
  width: 200px !important;
}

.rainwalk__devinfo {
  display: flex;
  justify-content: space-between;
  font-size: 15px;

  a {
    color: #000;
    transition: font-weight 250ms ease-in-out;

    &:hover {
      transition: font-weight 250ms ease-in-out;
      font: {
        weight: 500;
      }
    }
  }
}

@media (min-width: 480px) and (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
  .ant-drawer-wrapper-body {
    width: 100vw !important;
  }
  .ant-drawer-content-wrapper {
    width: 100vw !important;
  }
}
