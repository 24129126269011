@import './rainwalk';

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  overflow: hidden;
}